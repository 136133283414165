import Link from 'next/link';
import React from 'react';
import isString from '../../utils/isString';
import Button, { ButtonColor } from './Button';
import styles from './LinkButton.module.scss';
interface LinkButtonProps {
  color?: ButtonColor;
  href: React.ComponentProps<typeof Link>['href'];
  value: string | React.ReactNode;
  width?: string;
  shallow?: boolean;
  openInNewTab?: boolean;
  fontWeight?: string;
  onClick?: () => void;
  className?: string;
}
const LinkButton = ({
  href,
  value,
  color = 'primary',
  openInNewTab = false,
  onClick,
  className,
  shallow
}: LinkButtonProps) => {
  return <>
      {openInNewTab && isString(href) ? <a href={href} target="_blank" rel="noopener noreferrer" className={`${className || ''} ${styles.link}`}>
          <Button color={color} onClick={onClick}>
            {value}
          </Button>
        </a> : <Link href={href} shallow={shallow}>
          <Button onClick={onClick} color={color} className={`${className || ''} ${styles.link}`}>
            {value}
          </Button>
        </Link>}
    </>;
};
export default LinkButton;