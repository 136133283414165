import { useTranslation } from 'next-i18next';
import React from 'react';
import ShowMoreButtonWithBackground from 'views/games/components/ShowMoreButtonWithBackground';
import ViewMoreBG from 'views/games/components/ViewMoreBg';
import CircularLoadingIndicator from '../../assets/svgs/CircularLoadingIndicator';
import { PAGE_CONTENT_ID } from '../../layouts/constants';
import { Flex } from '../Flex';
import Heading from '../Text/Heading';
import NoGamesCard from './NoGamesCard';
import styles from './CardGrid.module.scss';
interface CardGridProps {
  /**
   * How many cards do we want to fit across?
   * Pass a record to map the number of items to fit
   * against the corresponding breakpoints
   */
  heading?: string | React.ReactNode;

  /** Total number of entries */
  entries: number;

  /** Is the actual data empty; excluding polyfills? */
  empty?: boolean;
  emptyState?: React.ReactNode;

  /** Are there more from the backend that we can fetch? */
  canLoadMore?: boolean;
  onClickLoadMore?: () => void;
  canViewMore?: boolean;
  viewMoreOptions?: {
    category?: string;
    href?: string;
  };

  /** The initial number of rows, before loading more. */
  initialRows?: number;
  loading?: boolean;
  children: React.ReactNode;
  customDisplayingText?: string;
  classNames?: Partial<{
    cardGridElement: string;
  }>;
}
const CardGrid = ({
  heading,
  entries,
  children,
  empty: isEmpty,
  emptyState,
  canLoadMore,
  onClickLoadMore,
  canViewMore,
  viewMoreOptions,
  loading = false,
  customDisplayingText,
  classNames
}: CardGridProps) => {
  const {
    t
  } = useTranslation();
  const childrenCount = React.Children.count(children);
  const empty = isEmpty || childrenCount === 0 && !loading;
  const displayViewMoreButton = canViewMore && childrenCount < entries;
  const allAreDisplayed = !loading && !empty && childrenCount === entries;
  const percentageDisplayed = entries === 0 ? 0 : 100 * (childrenCount / entries);
  const displayFetchMoreButton = canLoadMore && onClickLoadMore && percentageDisplayed !== 100;
  return <div className={styles.cardGridWrapper}>
      {!!heading && <Heading level={3}>{heading}</Heading>}

      <div className={`${styles.cardGridElement} ${classNames?.cardGridElement || ''}`}>
        {children}
      </div>

      {displayFetchMoreButton && <ShowMoreButtonWithBackground margin={false} onClick={onClickLoadMore} loading={loading} percentage={percentageDisplayed} />}

      {displayViewMoreButton && <ViewMoreBG margin={false} href={viewMoreOptions?.href ?? '#'} onClick={() => {
      const node = document.getElementById(PAGE_CONTENT_ID);
      if (node) {
        node.scrollTo(0, 0);
      }
    }} buttonText={t('btwViewMoreGameCategory', {
      category: viewMoreOptions?.category,
      total: entries
    })} />}

      {allAreDisplayed && <div className={styles.padding}>
          <Flex justify="center" align="center" gap="0.5rem">
            {onClickLoadMore && <CircularLoadingIndicator percentage={100} />}
            <span className={styles.cardGridDisplayingText}>
              {customDisplayingText ? customDisplayingText : t('txtGamesDisplayed', {
            count: entries
          })}
            </span>
          </Flex>
        </div>}

      {empty && !loading && emptyState}

      {empty && !loading && !emptyState && <NoGamesCard />}
    </div>;
};
export default CardGrid;