import React from 'react';
import styles from './ShowMoreBackground.module.scss';
export const ShowMoreBackground = ({
  children,
  className,
  $height,
  onClick,
  $margin
}: {
  $margin: boolean;
  $height: number;
  className?: string;
  children: React.ReactNode;
  onClick: () => void;
}) => <div onClick={onClick} className={`${styles.root} ${className || ''}`} style={{
  height: $height,
  paddingTop: $height / 2,
  marginBottom: $margin ? '2rem' : 0
}}>
    {children}
  </div>;