import colors from 'assets/styles/colors';
import TallGameCard from 'components/Cards/TallGameCard';
import GameCategoryIcon from 'components/GameCategoryIcon';
import ProviderCarousel from 'components/carousels/ProviderCarousel';
import { Game, GameCategoryType, GetLobbyGamesQuery, OriginalGame } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
// Image imports statically for build-time optimization
// Ref. https://nextjs.org/docs/basic-features/image-optimization#local-images
import { StaticImageData } from 'next/image';
import { useCallback } from 'react';
import { categoryLink, providerLink } from 'utils/linkGenerator';
import ogBlackjackTall from '../../../public/images/banners/originals-blackjack-tall.png';
import ogCrashTall from '../../../public/images/banners/originals-crash-tall.png';
import ogCrash from '../../../public/images/banners/originals-crash.png';
import ogDiceTall from '../../../public/images/banners/originals-dice-tall.png';
import ogDice from '../../../public/images/banners/originals-dice.png';
import ogHiloTall from '../../../public/images/banners/originals-hilo-tall.png';
import ogHilo from '../../../public/images/banners/originals-hilo.png';
import ogKenoTall from '../../../public/images/banners/originals-keno-tall.png';
import ogKeno from '../../../public/images/banners/originals-keno.png';
import ogLimboTall from '../../../public/images/banners/originals-limbo-tall.png';
import ogLimbo from '../../../public/images/banners/originals-limbo.png';
import ogMinesTall from '../../../public/images/banners/originals-mines-tall.png';
import ogMines from '../../../public/images/banners/originals-mines.png';
import ogPlinkoTall from '../../../public/images/banners/originals-plinko-tall.png';
import ogPlinko from '../../../public/images/banners/originals-plinko.png';
import { Carousel } from '../../components/Carousel/Carousel';
import styles from './HomeTabLobby.module.scss';
type ImageDeviceMap = {
  mobile: StaticImageData;
  desktop?: StaticImageData;
};

// NOTE: Images for original games are hardcoded for now
const originalImagePrefix = new Map<string, ImageDeviceMap>();
originalImagePrefix.set('originals/crash', {
  mobile: ogCrashTall,
  desktop: ogCrash
});
originalImagePrefix.set('originals/blackjack', {
  mobile: ogBlackjackTall
});
originalImagePrefix.set('originals/dice', {
  mobile: ogDiceTall,
  desktop: ogDice
});
originalImagePrefix.set('originals/plinko', {
  mobile: ogPlinkoTall,
  desktop: ogPlinko
});
originalImagePrefix.set('originals/mines', {
  mobile: ogMinesTall,
  desktop: ogMines
});
originalImagePrefix.set('originals/limbo', {
  mobile: ogLimboTall,
  desktop: ogLimbo
});
originalImagePrefix.set('originals/keno', {
  mobile: ogKenoTall,
  desktop: ogKeno
});
originalImagePrefix.set('originals/hilo', {
  mobile: ogHiloTall,
  desktop: ogHilo
});
const firstGame = OriginalGame.WHEEL; // The first game shown in the original games carousel

const HomeTabLobby = ({
  gamesData
}: {
  gamesData: GetLobbyGamesQuery | undefined;
}) => {
  const {
    t
  } = useTranslation();
  const originalGameNodes = (gamesData?.originals?.nodes ?? []).filter(g => !!g.originalGame);
  const firstGameNode = originalGameNodes?.find(g => g.originalGame === firstGame);
  const originalGames = firstGameNode ? [firstGameNode, ...originalGameNodes.filter(g => g.originalGame !== firstGame)] : originalGameNodes;
  const categoricalFilter = useCallback((nodes?: Game[] | null) => (nodes ?? []).filter(g => !!g.image?.key), []);
  return <div className={styles.homeTabLobbyWrapper}>
      <Carousel status={'completed'} headingUrl={providerLink('shuffle-games')} viewAllUrl={providerLink('shuffle-games')} heading={<>
            <GameCategoryIcon category={GameCategoryType.ORIGINALS} color={colors.primaryViolet} scale={1.3} />
            <span>{t('sectionShuffleGames')}</span>
          </>}>
        {originalGames.map(game => <TallGameCard key={game.id} game={(game as Game)} />)}
      </Carousel>

      <Carousel status={'completed'} headingUrl={categoryLink('slots')} viewAllUrl={categoryLink('slots')} shouldShowViewAllCard heading={<>
            <GameCategoryIcon category={GameCategoryType.SLOTS} color={colors.primaryViolet} scale={1.3} />
            <span>{t('gameSlots')}</span>
          </>}>
        {categoricalFilter((gamesData?.slots?.nodes as Game[])).map(game => <TallGameCard key={game.id} game={(game as Game)} />)}
      </Carousel>

      <Carousel status={'completed'} headingUrl={categoryLink('live-casino')} viewAllUrl={categoryLink('live-casino')} shouldShowViewAllCard heading={<>
            <GameCategoryIcon category={GameCategoryType.LIVE_CASINO} color={colors.primaryViolet} scale={1.3} />
            <span>{t('gameLiveCasino')}</span>
          </>}>
        {categoricalFilter((gamesData?.liveCasino?.nodes as Game[])).map(game => <TallGameCard key={game.id} game={(game as Game)} />)}
      </Carousel>

      <ProviderCarousel />

      <Carousel status={'completed'} headingUrl={categoryLink('game-shows')} viewAllUrl={categoryLink('game-shows')} shouldShowViewAllCard heading={<>
            <GameCategoryIcon category={GameCategoryType.GAME_SHOWS} color={colors.primaryViolet} scale={1.3} />
            <span>{t('gameGameShows')}</span>
          </>}>
        {categoricalFilter((gamesData?.gameShows?.nodes as Game[])).map(game => <TallGameCard key={game.id} game={(game as Game)} />)}
      </Carousel>

      <Carousel status={'completed'} headingUrl={categoryLink('table-games')} viewAllUrl={categoryLink('table-games')} shouldShowViewAllCard heading={<>
            <GameCategoryIcon category={GameCategoryType.TABLE_GAMES} color={colors.primaryViolet} scale={1.3} />
            <span>{t('gameTableGames')}</span>
          </>}>
        {categoricalFilter((gamesData?.tableGames?.nodes as Game[])).map(game => <TallGameCard key={game.id} game={(game as Game)} />)}
      </Carousel>

      <Carousel status={'completed'} headingUrl={categoryLink('latest-releases')} viewAllUrl={categoryLink('latest-releases')} shouldShowViewAllCard heading={<>
            <GameCategoryIcon category={GameCategoryType.LATEST_RELEASES} color={colors.primaryViolet} scale={1.3} />
            <span>{t('latestReleases')}</span>
          </>}>
        {categoricalFilter((gamesData?.latestReleases?.nodes as Game[])).map(game => <TallGameCard key={game.id} game={(game as Game)} />)}
      </Carousel>
    </div>;
};
export default HomeTabLobby;