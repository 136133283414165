import HeroBannerCard from 'components/Cards/HeroBannerCard';
import Container from 'components/Container/Container';
import { StaticImageData } from 'next/image';
import React from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from '../../components/Carousel/Carousel';
import { RightSidebarVariant } from '../../redux/slices/browserPreferenceSlice';
import styles from './HomeBannerSection.module.scss';
export type BannerSectionVariant = 'casino' | 'sports';
interface HomeBannerSectionProps {
  type: BannerSectionVariant;
  banners?: BannerItem[];
}
export type BannerItem = {
  type: BannerSectionVariant;
  image: string | StaticImageData;
  href: string;
  alt: string;
};
const HomeBannerSection = ({
  type = 'casino',
  banners
}: HomeBannerSectionProps) => {
  const rightSidebarVariant = useSelector((state: AppState) => state.browserPreference.rightSidebarVariant);
  const cards: BannerItem[] = (banners?.filter(banner => banner.type === type) ?? []).map(card => {
    return {
      ...card,
      href: `${type === 'sports' ? '/sports' : ''}${card.href}`
    };
  });
  return <Container className={`${styles.newHomeBannerCarousel} ${rightSidebarVariant === RightSidebarVariant.NONE ? '' : styles.applyContentSize}`}>
      <Carousel variant={'large'} heading={''} status={''}>
        {cards.map((card, key) => <HeroBannerCard key={key} {...card} />)}
      </Carousel>
    </Container>;
};
export default React.memo(HomeBannerSection);