import WrappedImage, { nextImageLoader } from 'components/WrappedImage';
import { GetGameBySlugQuery } from 'generated/graphql';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { gameLink, getImageFromKey } from 'utils/linkGenerator';
import { LOADED_PAGES } from '../../../layouts/PageLoader';
import { GameCardBorder } from '../GameCardBorder';
import { GameTilePlaceholder } from './GameTilePlaceholder';
import styles from './TallGameCard.module.scss';
interface TallGameCardProps {
  // No game when we're loading
  game?: GetGameBySlugQuery['game'];
  hidePreview?: boolean;
  onSelected?: (payload: React.MouseEvent) => void;
  className?: string;
}
const HOVER_DELAY = 500;

/**
 * The default game card for displaying original and provider games.
 * @note 30 JAN 2023 - Removed GameCardPreview for launch.
 */
const TallGameCard = ({
  game,
  className,
  onSelected = () => {}
}: TallGameCardProps) => {
  const image = useMemo(() => getImageFromKey(game?.image?.key, !game?.name), [game]);
  const onMouseOver = () => {
    const link = gameLink(game?.slug ?? '');
    if (!LOADED_PAGES.has(link)) {
      setTimeout(() => {
        LOADED_PAGES.add(gameLink(game?.slug ?? ''));
      }, HOVER_DELAY);
    }
  };
  return <Link className={`${styles.root} ${className || ''}`} onClick={onSelected} prefetch={false} href={gameLink(game?.slug ?? '')} data-testid={game?.name} onMouseOver={onMouseOver}>
      <GameTilePlaceholder color={game?.accentColor}>
        {image && <WrappedImage loader={nextImageLoader} src={`${image}?auto=format`} layout="fill" objectFit="contain" sizes="180px" alt={game?.name ?? ''} />}
        <GameCardBorder $accent={game?.accentColor} />
      </GameTilePlaceholder>
    </Link>;
};
export default TallGameCard;