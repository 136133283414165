import styles from './CircularLoadingIndicator.module.scss';
interface CircularLoadingIndicatorProps {
  scale?: number;
  color?: string;
  viewBox?: string;
  rotate?: boolean;
  percentage: number;
}

// animates the loading indicator
const CircularLoadingIndicator = ({
  scale = 1,
  viewBox = '0 0 200 200',
  rotate = false,
  percentage
}: CircularLoadingIndicatorProps) => {
  const width = `${scale * 17}`;
  const height = `${scale * 17}`;
  const strokeDashoffset = 2 * Math.PI * 80 * (100 - percentage) / 100;
  return <div className={styles.rotatingAnimationWrapper}>
      <svg id="svg" width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} className={rotate ? styles.animate : ''}>
        <circle r="80" cx="100" cy="100" fill="transparent" strokeDasharray="502.64" strokeDashoffset="0" />
        <circle className={styles.bar} r="80" cx="100" cy="100" fill="transparent" strokeDasharray="502.64" strokeDashoffset="0" style={{
        strokeDashoffset
      }} />
      </svg>
    </div>;
};
export default CircularLoadingIndicator;