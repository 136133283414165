import WrappedImage from 'components/WrappedImage';
import { useImageSizes } from 'hooks/interface/useImageSizes';
import { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';
import styles from './HeroBannerCard.module.scss';
interface HeroBannerCard {
  image: StaticImageData | string;
  alt: string;
  href: string;
}

/**
 * Used at the top of the homepage.
 */
const HeroBannerCard = ({
  image,
  alt,
  href
}: HeroBannerCard) => {
  const imageSizes = useImageSizes('90vw', '45vw', '30vw');
  return <Link href={href} className={styles.root}>
      <WrappedImage src={image} alt={alt} layout="fill" objectFit="contain" sizes={imageSizes} priority />
    </Link>;
};
export default React.memo(HeroBannerCard);