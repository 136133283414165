import { MouseEventHandler, ReactNode } from 'react';
import styles from './SearchClearButton.module.scss';
export const SearchClearButton = ({
  children,
  onClick
}: {
  children: ReactNode;
  onClick?: MouseEventHandler;
}) => <div onClick={onClick} className={styles.root}>
    {children}
  </div>;