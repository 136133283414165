import { SvgProps } from 'assets/svgs/svg.type';
import { GameCategoryType } from 'generated/graphql';
import React from 'react';
const mapping = {
  [GameCategoryType.ORIGINALS]: 'original.svg',
  [GameCategoryType.LIVE_CASINO]: 'casino.svg',
  [GameCategoryType.ROULETTE]: 'roulette.svg',
  [GameCategoryType.SLOTS]: 'slots.svg',
  [GameCategoryType.GAME_SHOWS]: 'game-show.svg',
  [GameCategoryType.TABLE_GAMES]: 'table-games.svg',
  [GameCategoryType.BLACKJACK]: 'blackjack.svg',
  [GameCategoryType.BONUS_BUY]: 'bonus-buy.svg',
  [GameCategoryType.BACCARAT]: 'baccarat.svg',
  [GameCategoryType.SHUFFLE_PICKS]: 'bets-dice.svg',
  [GameCategoryType.LATEST_RELEASES]: 'latest-releases.svg'
};
const GameCategoryIcon = ({
  category,
  className,
  width,
  height
}: Partial<SvgProps> & {
  category?: GameCategoryType;
  className?: string;
  width?: number;
  height?: number;
}) => {
  const mapped = category && mapping?.[category] && mapping[category];
  return mapped ? <img src={`/icons/${mapped}`} alt={category} width={width} height={height} /> : <img src="/icons/bets-dice.svg" alt="dice" className={className} width={width} height={height} />;
};
export default React.memo(GameCategoryIcon);