import { useTranslation } from 'next-i18next';
import React from 'react';
import { Tooltip } from '../Tooltip';
import { TabItem, TabViewProps, TabWrapperProps } from './TabView.type';
import styles from './TabView.module.scss';
export const TAB_VIEW_ID = 'tab-view';
const TabView = ({
  tabs,
  activeTab,
  onClick,
  tooltipPlacement = 'bottom',
  wide = false,
  outline = false,
  className = ''
}: TabViewProps) => {
  const {
    t
  } = useTranslation();
  return <div className={`${styles.root} ${outline ? styles.outline : ''} ${wide ? styles.wideContent : ''} ${className || ''}`} id={TAB_VIEW_ID}>
      <div className={styles.tabsContainer}>
        {tabs.map(({
        id,
        name,
        Icon,
        icon,
        disabled,
        title
      }: TabItem) => {
        const active = activeTab === id;
        const iconComponent = Icon ? React.cloneElement(Icon, {
          color: 'currentColor'
        }) : null;
        return <TabWrapper title={title} key={id} tooltipPlacement={tooltipPlacement}>
              <button className={`${styles.tab} ${active ? styles.active : ''} ${disabled ? styles.disabled : ''} ${outline ? styles.outlineButton : ''} ${wide ? styles.wide : ''} ${Icon ? styles.hasIcon : ''}`} data-testid={id} id={id} disabled={disabled || active} onClick={e => onClick?.(e, id)}>
                {icon && <span className={styles.icon}>{icon}</span>}
                {Icon && <span className={styles.icon}>{iconComponent}</span>}
                <label>{t(name)}</label>
              </button>
            </TabWrapper>;
      })}
      </div>
    </div>;
};
const TabWrapper = React.memo(({
  children,
  title,
  tooltipPlacement
}: TabWrapperProps) => {
  const {
    t
  } = useTranslation();
  return title ? <Tooltip content={t(title)} placement={tooltipPlacement}>
      {children}
    </Tooltip> : <>{children}</>;
});
export default React.memo(TabView);