import { clientWithoutAuth } from 'apollo/clients';
import ProviderCard from 'components/Cards/ProviderCard';
import { useGetGameProvidersQuery } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import { providerLink } from 'utils/linkGenerator';
import { Carousel } from '../Carousel/Carousel';
const ProviderCarousel = () => {
  const {
    t
  } = useTranslation();
  const {
    data,
    loading
  } = useGetGameProvidersQuery({
    notifyOnNetworkStatusChange: true,
    client: clientWithoutAuth
  });
  return <Carousel status={loading ? 'loading' : ''} headingUrl={'/casino/providers'} heading={<>
          <img src="/icons/provider.svg" alt="provider" />
          <span>{t('sectionProviders')}</span>
        </>}>
      {data?.gameProviders.map(item => <ProviderCard key={item.name} name={item.name} image={item.image?.key || ''} href={providerLink(item.slug)} />)}
    </Carousel>;
};
export default ProviderCarousel;