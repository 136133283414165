import { useTranslation } from 'next-i18next';
import CircularLoadingIndicator from '../../../assets/svgs/CircularLoadingIndicator';
import { ShowMoreBackground } from '../ShowMoreBackground';
import styles from './ShowMoreButtonWithBackground.module.scss';
const DEFAULT_CARD_HEIGHT_PX = 150;
interface ShowMoreBGProps {
  onClick: () => void;
  loading: boolean;
  percentage: number;
  margin?: boolean;
  height?: number;
  className?: string;
}
const ShowMoreButtonWithBackground = ({
  onClick,
  percentage,
  loading,
  margin = true,
  height = DEFAULT_CARD_HEIGHT_PX,
  className
}: ShowMoreBGProps) => {
  const {
    t
  } = useTranslation();
  return <ShowMoreBackground $height={height} $margin={margin} onClick={onClick} className={className}>
      <div className={styles.button}>
        <CircularLoadingIndicator percentage={percentage} rotate={loading} />
        <p>{loading ? t('btnLoading') : t('btnShowMore')}</p>
        <img src="/icons/chevron.svg" alt="arrow down" />
      </div>
    </ShowMoreBackground>;
};
export default ShowMoreButtonWithBackground;