import Link from 'next/link';
import { useMemo } from 'react';
import { getImageFromKey } from 'utils/linkGenerator';
import styles from './ProviderCard.module.scss';
interface ProviderCardProps {
  image: string;
  name: string;
  href?: string;
}
const ProviderCardItem = ({
  image,
  name
}: ProviderCardProps) => {
  const imageSrc = useMemo(() => getImageFromKey(image, true), [image]);
  return <div className={styles.providerCardBlock}>
      <div className={styles.providerCardElement}>
        {imageSrc && <img className={styles.image} src={imageSrc} alt={name} />}
      </div>
    </div>;
};
const ProviderCard = ({
  image,
  name,
  href = ''
}: ProviderCardProps) => {
  return <div className={styles.ProviderCardWrapper} style={{
    cursor: href.length > 0 ? 'pointer' : 'default'
  }}>
      {href ? <Link href={href} passHref legacyBehavior>
          <a>
            <ProviderCardItem name={name} image={image} />
          </a>
        </Link> : <ProviderCardItem name={name} image={image} />}
    </div>;
};
export default ProviderCard;