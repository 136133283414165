import { clientWithoutAuth } from 'apollo/clients';
import colors from 'assets/styles/colors';
import CardGrid from 'components/Cards/CardGrid';
import TallGameCard from 'components/Cards/TallGameCard';
import GameCategoryIcon from 'components/GameCategoryIcon';
import UI from 'constants/ui';
import { Game, GameCategoryType, useGetGamesQuery } from 'generated/graphql';
import { useSkeleton } from 'hooks/useSkeletons';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { categoryLink } from 'utils/linkGenerator';
import { Flex } from '../../components/Flex';
import { AlertsEnum, addAlert } from '../../redux/slices/alertsSlice';
export interface HomeTabAbstractProps {
  heading: string;
  category: GameCategoryType | null;
}
interface Props extends HomeTabAbstractProps {
  search: string;
}
const FETCH_FIRST = 28;
const HomeTabAbstract = ({
  heading,
  category,
  search
}: Props) => {
  const dispatch = useDispatch();
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    t
  } = useTranslation();
  const {
    data,
    loading,
    fetchMore
  } = useGetGamesQuery({
    variables: {
      first: FETCH_FIRST,
      skip: 0,
      categories: category ? [(category as GameCategoryType)] : undefined,
      isOriginal: category === GameCategoryType.ORIGINALS,
      search: search
    },
    fetchPolicy: 'cache-and-network',
    client: clientWithoutAuth
  });
  const games = data?.games.nodes ?? [];
  const totalGames = data?.games.totalCount ?? 0;
  const polyfillGames = useSkeleton();
  const cards = loading ? polyfillGames : games;
  return <CardGrid heading={category && <Link href={categoryLink(category)} legacyBehavior>
            <a>
              <Flex align="center" justify="center" gap={UI.spacing.xs}>
                <GameCategoryIcon category={category} color={colors.primaryViolet} scale={1.3} />
                {t(heading)}
              </Flex>
            </a>
          </Link>} loading={loading || loadingMore} empty={!games.length} entries={totalGames} canLoadMore onClickLoadMore={async () => {
    if (data?.games.nodes) {
      try {
        setLoadingMore(true);
        await fetchMore({
          variables: {
            skip: data.games.nodes.length
          },
          updateQuery: (previousResult, {
            fetchMoreResult
          }) => {
            const games = previousResult?.games.nodes ?? [];
            const incomingGames = fetchMoreResult?.games.nodes ?? [];
            return {
              games: {
                totalCount: fetchMoreResult.games.totalCount,
                nodes: [...games, ...incomingGames]
              }
            };
          }
        });
      } catch {
        dispatch(addAlert({
          type: AlertsEnum.Error,
          message: t('ERROR_OCCURRED')
        }));
      }
      setLoadingMore(false);
    }
  }}>
      {cards.map(game => <TallGameCard key={game.id} game={(game as Game)} />)}
    </CardGrid>;
};
export default HomeTabAbstract;