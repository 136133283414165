import React from 'react';
import styles from './GameCardBorder.module.scss';
type Props = {
  children?: React.ReactNode | React.ReactNode[];
  $accent?: string;
};
export const GameCardBorder = ({
  children,
  $accent
}: Props) => <div className={styles.root} style={{
  border: `2px solid ${$accent ?? 'transparent'}`
}}>
    {children}
  </div>;