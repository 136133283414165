import Container from 'components/Container/Container';
import TextInput from 'components/FormControls/TextInput';
import GameCategoryIcon from 'components/GameCategoryIcon';
import TabView from 'components/TabView/TabView';
import { GameCategoryType, GetLobbyGamesQuery } from 'generated/graphql';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useDebounce } from 'react-use';
import { SearchClearButton } from '../../components/FormControls/SearchClearButton';
import { TabItem } from '../../components/TabView/TabView.type';
import HomeBannerSection, { BannerItem } from './HomeBannerSection';
import HomeTabAbstract from './HomeTabAbstract';
import HomeTabLobby from './HomeTabLobby';
import styles from './index.module.scss';
enum HomeTab {
  LOBBY = 'LOBBY',
  ORIGINALS = 'ORIGINALS',
  SLOTS = 'SLOTS',
  LIVE_CASINO = 'LIVE_CASINO',
  TABLE_GAMES = 'TABLE_GAMES',
}
const TAB_DATA: TabItem[] = [{
  id: HomeTab.LOBBY,
  name: 'tabLobby',
  Icon: <img src="/icons/home.svg" alt="home" />
}, {
  id: HomeTab.ORIGINALS,
  name: 'gameOriginals',
  Icon: <GameCategoryIcon category={GameCategoryType.ORIGINALS} scale={1.3} />
}, {
  id: HomeTab.SLOTS,
  name: 'gameSlots',
  Icon: <GameCategoryIcon category={GameCategoryType.SLOTS} scale={1.3} />
}, {
  id: HomeTab.LIVE_CASINO,
  name: 'gameLiveCasino',
  Icon: <GameCategoryIcon category={GameCategoryType.LIVE_CASINO} scale={1.3} />
}, {
  id: HomeTab.TABLE_GAMES,
  name: 'gameTableGames',
  Icon: <GameCategoryIcon category={GameCategoryType.TABLE_GAMES} scale={1.3} />
}];
const HOME_TAB_CATEGORIES: Record<HomeTab, GameCategoryType | null> = {
  [HomeTab.LOBBY]: null,
  [HomeTab.LIVE_CASINO]: GameCategoryType.LIVE_CASINO,
  [HomeTab.ORIGINALS]: GameCategoryType.ORIGINALS,
  [HomeTab.SLOTS]: GameCategoryType.SLOTS,
  [HomeTab.TABLE_GAMES]: GameCategoryType.TABLE_GAMES
};
const DEBOUNCE_TIME_MS = 250;
interface HomeProps {
  banners: BannerItem[];
  gamesData: GetLobbyGamesQuery | undefined;
}

// Used for the home page and the reset-password page
const Home = ({
  banners,
  gamesData
}: HomeProps) => {
  const [activeTab, setActiveTab] = useState<string>(HomeTab.LOBBY);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const {
    t
  } = useTranslation();
  const tabName = TAB_DATA.find(tab => tab.id === activeTab)?.name;
  useDebounce(() => {
    setDebouncedSearch(search);
  }, DEBOUNCE_TIME_MS, [search, setDebouncedSearch]);
  return <>
      <HomeBannerSection type="casino" banners={banners} />

      <Container className={styles.container}>
        <div className={styles.wrapper}>
          <TabView tabs={TAB_DATA} activeTab={activeTab} onClick={(_, id) => setActiveTab((id as HomeTab))} setActiveTab={setActiveTab} />

          <div className={styles.homeTabSectionSearch}>
            <TextInput placeholder={t('phSearch')} value={search} onChange={e => setSearch(e.target.value)} prefix={<img src="/icons/search.svg" alt="search" />} suffix={search.length > 0 ? <SearchClearButton onClick={() => setSearch('')}>
                    <img src="/icons/cross-white.svg" alt="cross" />
                  </SearchClearButton> : undefined} />
          </div>
        </div>
      </Container>

      <Container className={styles.bottomContainer}>
        <div className={styles.homeTabContainer}>
          {activeTab === HomeTab.LOBBY && !debouncedSearch ? <HomeTabLobby gamesData={gamesData} /> : <HomeTabAbstract heading={tabName ?? ''} category={HOME_TAB_CATEGORIES[(activeTab as keyof typeof HomeTab)]} search={debouncedSearch} />}
        </div>
      </Container>
    </>;
};
export default Home;