import { useTranslation } from 'next-i18next';
import { CardGridEmpty } from './CardGridEmpty';
const NoGamesCard = () => {
  const {
    t
  } = useTranslation();
  return <CardGridEmpty>
      <img src="/icons/cross.svg" loading="lazy" alt="cross" />
      <p>{t('noGamesToDisplay')}</p>
    </CardGridEmpty>;
};
export default NoGamesCard;