import LinkButton from 'components/Buttons/LinkButton';
import { useRouter } from 'next/router';
import { Flex } from '../../../components/Flex';
import { ShowMoreBackground } from '../ShowMoreBackground';
const DEFAULT_CARD_HEIGHT_PX = 150;
interface ViewMoreBGProps {
  href: string;
  buttonText: string;
  margin?: boolean;
  height?: number;
  onClick: () => void;
}
const ViewMoreBG = ({
  href,
  buttonText,
  margin = true,
  height = DEFAULT_CARD_HEIGHT_PX,
  onClick
}: ViewMoreBGProps) => {
  const router = useRouter();
  const onClickBackground = async () => {
    await router.push(href);
    onClick();
  };
  return <ShowMoreBackground $height={height} $margin={margin} onClick={onClickBackground}>
      <Flex align="center" justify="center">
        <LinkButton href={href} value={buttonText} />
      </Flex>
    </ShowMoreBackground>;
};
export default ViewMoreBG;